import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Link,
  Typography,
} from '@material-ui/core';
import { Error, ExpandMore } from '@material-ui/icons';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useMemo } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { DeliveryAltIcon } from 'shared/icons/DeliveryAltIcon';
import { PickupAltIcon } from 'shared/icons/PickupAltIcon';
import { useFlag } from 'shared/settings/FeatureToggles';
import { InlinePhotoGallery } from 'shared/ui/InlinePhotoGallery';
import { joinStrings } from 'shared/utils/StringUtils';
import { LoadDTO, LoadVehicleInspectionDTO, PhotoDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { ViewLoadAIAGVehicleInspection } from './ViewLoadAIAGVehicleInspection';

interface VehicleInspectionProps extends LoadVehicleInspectionDTO {
  expanded?: boolean;
  online_bol_url: string;
}

function VehicleInspection({
  expanded,
  year,
  make,
  model,
  vin,
  photos,
  videos,
  online_bol_url,
}: VehicleInspectionProps) {
  const isVideoInspectionEnabled = useFlag('video_inspection_carrier_tms');

  const galleryPhotos = useMemo((): PhotoDTO[] => {
    if (photos && photos.length > 0) {
      return photos.map((photo) => ({
        id: photo.guid,
        url: photo.rendered_photo,
        thumbnail_url: photo.rendered_photo_thumbnail,
        is_vehicle_detected: photo.is_vehicle_detected,
        type: photo.type,
        damages: photo.damages,
      }));
    }
    return [];
  }, [photos]);

  const damagedPhotosCount = useMemo(() => {
    if (!photos?.length) return 0;
    const damagesPhotosCount = photos.reduce((acc, curr) => {
      if (curr.damages) {
        acc += curr.damages.length;
      }
      return acc;
    }, 0);
    return damagesPhotosCount;
  }, [photos]);

  const undetectedVehiclePhotos = photos?.filter(
    (p) => p.is_vehicle_detected === false,
  );

  return (
    <Accordion defaultExpanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="inspection-content"
        id="inspection-header"
      >
        <Inline space="xsmall">
          <Typography variant="body1">
            {joinStrings(' ', year, make, model)}
          </Typography>
          <Typography color="textSecondary">{vin}</Typography>
          {damagedPhotosCount > 0 ? (
            <Typography color="textSecondary">
              · {damagedPhotosCount}{' '}
              {formatPlural(damagedPhotosCount, 'Damage', 'Damages')}
            </Typography>
          ) : (
            <Typography color="textSecondary">No Damages</Typography>
          )}

          {!!undetectedVehiclePhotos?.length && (
            <Box
              backgroundColor="Red50"
              display="inline-block"
              paddingLeft="xxsmall"
              paddingRight="xxsmall"
              borderRadius="small"
            >
              <Inline verticalAlign="center" space="xxsmall">
                <Error fontSize="small" color="error" />
                <Link
                  href={parseURITemplate(
                    `${online_bol_url}{?utm_source,utm_medium,utm_content}`,
                    {
                      utm_source: 'CTMS Web',
                      utm_medium: 'View Load Page',
                      utm_content: 'Non-vehicle Photo Issues Link',
                    },
                  )}
                  onClick={() => {
                    trackLoadsEvent({
                      name: 'Carrier Clicked Non-vehicle Photo Issues',
                      page: 'view_load',
                    });
                  }}
                >
                  {undetectedVehiclePhotos.length} Inspection photo{' '}
                  {formatPlural(
                    undetectedVehiclePhotos.length,
                    'issue',
                    'issues',
                  )}
                </Link>
              </Inline>
            </Box>
          )}
        </Inline>
      </AccordionSummary>
      <AccordionDetails>
        {galleryPhotos.length > 0 ? (
          <InlinePhotoGallery
            videos={isVideoInspectionEnabled ? videos : undefined}
            photos={galleryPhotos}
            onView={(type) => {
              if (type === 'video') {
                trackLoadsEvent({
                  name: 'Carrier Clicked Video Inspection',
                });
              }
            }}
          />
        ) : (
          <Typography color="textSecondary">No inspection photos</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

interface ViewLoadInspectionDetailsProps {
  load: LoadDTO;
}

export function ViewLoadInspectionDetails({
  load: {
    signatures: { pickup_signature_address, delivery_signature_address },
    inspection_photos,
    inspection_info,
    online_bol_url,
    inspection_type,
  },
}: ViewLoadInspectionDetailsProps) {
  const isVideoInspectionEnabled = useFlag('video_inspection_carrier_tms');

  const hasSignatureAddress =
    !!pickup_signature_address || !!delivery_signature_address;

  const inspectionList = useMemo(() => {
    if (isVideoInspectionEnabled) {
      if (inspection_info && inspection_info.length > 0) {
        return inspection_info;
      }
      return inspection_photos;
    }
    if (inspection_photos && inspection_photos.length > 0) {
      return inspection_photos;
    }
    return inspection_info;
  }, [isVideoInspectionEnabled, inspection_info, inspection_photos]);

  if (!inspectionList?.length) {
    return null;
  }

  const InspectionComponent =
    inspection_type !== 'aiag'
      ? VehicleInspection
      : ViewLoadAIAGVehicleInspection;

  return (
    <Card aria-label="Inspection Card">
      <CardContent>
        <Stack space="small">
          <Stack space="small">
            <Typography variant="h3">Inspection Details</Typography>
            {hasSignatureAddress && (
              <Columns space="medium">
                {pickup_signature_address && (
                  <Column>
                    <Columns align="center" space="xxsmall">
                      <Column width="content">
                        <PickupAltIcon fontSize="small" color="action" />
                      </Column>
                      <Column width="fluid">
                        <Typography color="textSecondary" display="inline">
                          Pickup Contact signed inspection at{' '}
                          {pickup_signature_address}
                        </Typography>
                      </Column>
                    </Columns>
                  </Column>
                )}

                {!!delivery_signature_address && (
                  <Column>
                    <Columns align="center" space="xxsmall">
                      <Column width="content">
                        <DeliveryAltIcon fontSize="small" color="action" />
                      </Column>
                      <Column width="fluid">
                        <Typography color="textSecondary">
                          Delivery Contact signed inspection at{' '}
                          {delivery_signature_address}
                        </Typography>
                      </Column>
                    </Columns>
                  </Column>
                )}
              </Columns>
            )}
          </Stack>

          <Stack>
            {inspectionList.map((inspection, idx) => (
              <InspectionComponent
                key={inspection.guid}
                expanded={idx === 0}
                online_bol_url={online_bol_url}
                {...inspection}
              />
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
