import { ReactElement, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthState } from 'shared/auth/AuthState';
import { CARRIER_GUID, getAuthRole } from 'shared/auth/AuthToken';
import { useSignUpEvent } from 'shared/auth/SignupPageUtils';
import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from 'shared/errors/data/useShowTrialExpiredDialog';
import { PaywallDialog } from 'shared/errors/PaywallDialog';
import { identifyAnalytics } from 'shared/helpers/Analytics';
import {
  identifyErrorTrackerUser,
  initializeUserMonitor,
} from 'shared/helpers/ErrorTracker';
import { hasSuperDispatchExtensionInstalled } from 'shared/helpers/ExtensionHelper';
import { useViewportMeta } from 'shared/helpers/ResponsiveHelpers';
import { setCookie } from 'shared/helpers/StorageHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { NavigationHeaderBanner } from 'shared/layout/NavigationHeaderBanner';
import { NotificationStack } from 'shared/layout/notification-stack/NotificationStack';
import { NavigationBarContainer } from 'shared/layout/root/NavigationBarContainer';
import { AppLayoutSidebarLegacy } from 'shared/layout/sidebar/AppLayoutSidebarLegacy';
import { NavbarBottomBar } from 'shared/layout/sidebar/NavbarBottomBar';
import { NavbarContextProvider } from 'shared/layout/sidebar/NavbarContext';
import { useBottomItems } from 'shared/layout/sidebar/useBottomItems';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { ChromeExtensionReminderSnackBar } from 'shared/modules/chrome-extension-reminder/ChromeExtensionReminderSnackBar';
import { useDriverActivationExperiment } from 'shared/modules/experiments/driver-activation/DriverActivationExperiment';
import { useRedirectToSLBExperiment } from 'shared/modules/experiments/redirect-to-slb-experiment/RedirectToSLBExperiment';
import { useLoadboardNotifications } from 'shared/modules/loadboard/LoadboardNotifications';
import { OnboardingDialogs } from 'shared/modules/onboarding/OnboardingDialogs';
import { useOnboardingTourHandler } from 'shared/modules/onboarding/OnboardingIntercomTours';
import { useRatingNotifications } from 'shared/modules/ratings/data/RatingNotifications';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { SubscriptionAddBillingAddressDialog } from 'shared/modules/subscription/SubscriptionAddBillingAddressDialog';
import { useSubscriptionDetails } from 'shared/modules/subscription/SubscriptionAPI';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { TrialExpiredDialog } from '../loadboard/core/TrialExpiredDialog';

const localPaths = {
  vehicles: '/vehicles',
  dashboard: '/dashboard',
  loads: '/loads',
  trips: '/trips',
  tracking: '/tracking',
  drivers: '/drivers',
  contacts: '/contacts',
  ratings: '/profile/overview',
  dispatcherManagement: '/dispatchers?utm_medium=navigation_bar',
  dispatcherProfile: '/dispatchers/profile',
  carrierProfile: '/profile',
  reports: '/reports',
  settings: '/settings',
  subscriptionDetails: '/settings/subscription',
  logout: '/logout',
} as const;

interface CarrierAppRoutesContainerProps {
  children: ReactElement;
}

function CarrierAppRoutesContainer({
  children,
}: CarrierAppRoutesContainerProps) {
  const authState = useAuthState();
  const shouldShowNotifications = useFlag('react_notification_banners');
  const shouldShowAdminLoginWarningBanner = useFlag(
    'admin_login_warning_banner',
  );
  const { isOpenUpgradeWarningDialog, closeUpgradeWarningDialog } =
    useShowAddBillingAddressDialog();
  const {
    isOpenSubscriptionCancelledDialog,
    closeSubscriptionCancelledDialog,
    trialType,
  } = useShowSubscriptionCancelledDialog();
  const { isOpenTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { data: subscriptionDetails } = useSubscriptionDetails();
  const role = getAuthRole();

  const { bottomItems, hasMenuBadge } = useBottomItems();

  useSignUpEvent();

  if (authState === 'undetermined') {
    return <SplashScreen />;
  }

  return (
    <NavbarContextProvider>
      {shouldShowNotifications && <NotificationStack />}

      <ChromeExtensionReminderSnackBar />
      <OnboardingDialogs />

      {shouldShowAdminLoginWarningBanner && role === 'admin' && (
        <NavigationHeaderBanner />
      )}
      <SubscriptionAddBillingAddressDialog
        open={Boolean(isOpenUpgradeWarningDialog)}
        onClose={closeUpgradeWarningDialog}
      />

      <TrialExpiredDialog
        open={
          Boolean(isOpenSubscriptionCancelledDialog) &&
          !!subscriptionDetails?.customer?.payment_method
        }
        onClose={() => {
          closeSubscriptionCancelledDialog();
          openExternalURL('/loadboard');
        }}
        onReactivate={() => {
          closeSubscriptionCancelledDialog();
          openExternalURL('/tms/settings/subscription');
        }}
        type={trialType === 'expired' ? 'trial_expired' : 'cancelled'}
      />

      {isOpenTrialExpiredDialog &&
        subscriptionDetails?.subscription?.plan?.name === 'lite' &&
        !subscriptionDetails?.customer?.payment_method && (
          <PaywallDialog isTrialExpired={isOpenTrialExpiredDialog} />
        )}

      <NavigationBarContainer
        navigationBar={
          <AppLayoutSidebarLegacy
            hasMenuBadge={hasMenuBadge}
            localPaths={localPaths}
          />
        }
        mobileMenuBar={
          <NavbarBottomBar
            items={bottomItems}
            hasMenuBadge={hasMenuBadge}
            localPaths={localPaths}
          />
        }
      >
        {children}
      </NavigationBarContainer>
    </NavbarContextProvider>
  );
}

export function CarrierOutlet() {
  const { data: settings } = useCarrierSettings();
  const hasExtensionInstalled = hasSuperDispatchExtensionInstalled();
  const isWebView = /mobile/i.test(navigator.userAgent);

  useViewportMeta({
    width: 960,
    disabled: isWebView,
  });
  useLoadboardNotifications();
  useRatingNotifications();
  useDriverActivationExperiment();
  useRedirectToSLBExperiment();

  useOnboardingTourHandler({
    tourName: 'tour_verification_approval',
    enabled: settings?.carrier.verification_status === 'approved',
  });

  useEffect(() => {
    const user = settings?.user;
    const carrier = settings?.carrier;
    const isSuper = settings?.carrier.verification_status === 'approved';

    if (carrier?.guid) {
      setCookie(CARRIER_GUID, carrier.guid);
    }

    if (user) {
      void initializeUserMonitor();
      identifyErrorTrackerUser({
        id: user.guid,
        carrier_guid: settings.carrier.guid,
        carrier_name: settings.carrier.name,
      });
      identifyAnalytics({
        guid: user.guid,
        hash: user.user_hash,
        accountType: settings.company_type,
        experiment_group: settings.groups?.map((e) => e.number),
        sd_importer_installed: hasExtensionInstalled,
        company: {
          id: carrier?.guid,
          guid: carrier?.guid,
          name: carrier?.name,
          is_super: isSuper,
          superpay_onboarding_status: settings.superpay.verification,
          is_fmcsa_phone_verified: settings.is_fmcsa_phone_verified,
        },
      });
    } else {
      identifyAnalytics();
    }
  }, [settings, hasExtensionInstalled]);
  return (
    <CarrierAppRoutesContainer>
      <Outlet />
    </CarrierAppRoutesContainer>
  );
}
