import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const CenterBox = styled(Box)`
  align-items: center;
  justify-content: normal;
`;

export function DriverEmptyContainer() {
  return (
    <CenterBox height="100%" display="flex">
      <Stack align="center">
        <Typography variant="h2" align="center">
          No Drivers
        </Typography>
        <Box maxWidth="280px">
          <Typography variant="subtitle2" color="textSecondary" align="center">
            To assign your drivers to the loads let&apos;s add them first at the
            top left
          </Typography>
        </Box>
      </Stack>
    </CenterBox>
  );
}
