import { Contacts, Dashboard, Place } from '@material-ui/icons';
import { useDeepEqualValue } from '@superdispatch/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAPISocketMessages } from 'shared/api/APISocketMessages';
import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from 'shared/errors/data/useShowTrialExpiredDialog';
import { trackEvent } from 'shared/helpers/Analytics';
import { DriverSidebarIcon } from 'shared/icons/DriverSidebarIcon';
import { LoadsSidebarIcon } from 'shared/icons/LoadsSidebarIcon';
import { ReportsSidebarIcon } from 'shared/icons/ReportsSidebarIcon';
import { SLBSidebarIcon } from 'shared/icons/SLBSidebarIcon';
import { TripsSidebarIcon } from 'shared/icons/TripsSidebarIcon';
import { SidebarLinkLegacy } from 'shared/layout/sidebar/AppSidebarMenuLegacy';
import {
  useDriverCountCache,
  useRequestedDriversCount,
} from 'shared/modules/driver/DriverPreferencesAPI';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { LoadOffersLink } from './AppLayoutSidebarLegacy';
import { AppSidebarLoadSuggestionsLink } from './AppSidebarLoadSuggestionsLink';
import { useLoadboardNudgeIndicator } from './useLoadboardNudgeIndicator';
interface SidebarItemsProps {
  localPaths?: {
    dashboard?: string;
    loads?: string;
    contacts?: string;
    drivers?: string;
    trips?: string;
    tracking?: string;
    loadboard?: string;
    ratings?: string;
    dispatcherProfile?: string;
    carrierProfile?: string;
    reports?: string;
    settings?: string;
    subscriptionDetails?: string;
    logout?: string;
  };
}

export function useSidebarItems({
  localPaths: localPathsProp = {},
}: SidebarItemsProps) {
  const queryClient = useQueryClient();
  const { data: settings } = useCarrierSettings();
  const shouldShowReportsLink = settings?.user.has_access_to_reports;
  const localPaths = useDeepEqualValue(localPathsProp);
  const shouldShowNewDashboard = useFlag('ctms_new_dashboard');
  const { isIndicatorVisible } = useLoadboardNudgeIndicator();
  const { data } = useRequestedDriversCount();
  const { setDriverCount } = useDriverCountCache();
  const { closeTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { closeSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();
  useAPISocketMessages<{ join_requests: number }>((payload) => {
    if (payload.meta.type === 'drivers_count') {
      setDriverCount(payload.data.join_requests);
      void queryClient.invalidateQueries(['drivers']);
    }
  });

  const [offersLinks, primaryLinks, secondaryLinks] = useMemo(() => {
    const offers: SidebarLinkLegacy[] = [
      {
        key: 'loadboard',
        label: 'Loadboard',
        href: '/loadboard',
        Icon: SLBSidebarIcon,
        localPath: localPaths.loadboard,
        badgeContent: isIndicatorVisible ? ' ' : undefined,
        badgeType: 'dot',
        onClick: () => {
          closeTrialExpiredDialog();
          closeSubscriptionCancelledDialog();
          trackEvent('Carrier Clicked Loadboard', {
            has_counter: false,
          });
        },
        dataIntercomTarget: 'loadboard-sidebar-menu-item',
      },
      {
        key: 'suggested-loads',
        label: 'Suggested Loads',
        href: '/tms/suggested-loads',
        RenderComponent: AppSidebarLoadSuggestionsLink,
      },
      {
        key: 'load-offers',
        label: '',
        href: '',
        RenderComponent: LoadOffersLink,
        dataIntercomTarget: 'load-offers-sidebar-menu-item',
      },
    ];

    const primary: SidebarLinkLegacy[] = [
      shouldShowNewDashboard
        ? {
            key: 'dashboard',
            label: 'Dashboard',
            href: '/tms/dashboard',
            Icon: Dashboard,
            localPath: localPaths.dashboard,
          }
        : {
            key: 'dashboard',
            label: 'Dashboard',
            href: '/tms/dashboard',
            Icon: Dashboard,
            localPath: localPaths.dashboard,
          },
      {
        key: 'loads',
        label: 'Loads',
        href: '/tms/loads',
        Icon: LoadsSidebarIcon,
        localPath: localPaths.loads,
      },
      {
        key: 'trips',
        label: 'Trips',
        href: '/tms/trips',
        Icon: TripsSidebarIcon,
        localPath: localPaths.trips,
      },
      {
        key: 'tracking',
        label: 'Tracking',
        href: '/tms/tracking',
        Icon: Place,
        localPath: localPaths.tracking,
      },
    ];

    const secondary: SidebarLinkLegacy[] = [
      {
        key: 'contacts',
        label: 'Contacts',
        href: '/tms/contacts',
        Icon: Contacts,
        localPath: localPaths.contacts,
      },
      {
        key: 'drivers',
        label: 'Drivers',
        href: '/tms/drivers',
        badgeType: 'standard',
        Icon: DriverSidebarIcon,
        localPath: localPaths.drivers,
        badgeContent: data?.join_requests ? data.join_requests : '',
        dataIntercomTarget: 'drivers-sidebar-menu-item',
      },

      {
        key: 'reports',
        label: 'Reports',
        Icon: ReportsSidebarIcon,
        hidden: !shouldShowReportsLink,
        href: '/tms/reports',
        localPath: localPaths.reports,
      },
    ];

    return [offers, primary, secondary] as const;
  }, [
    localPaths.contacts,
    localPaths.dashboard,
    localPaths.drivers,
    localPaths.loadboard,
    localPaths.loads,
    localPaths.reports,
    localPaths.tracking,
    localPaths.trips,
    shouldShowReportsLink,
    shouldShowNewDashboard,
    isIndicatorVisible,
    data,
    closeTrialExpiredDialog,
    closeSubscriptionCancelledDialog,
  ]);

  return { offersLinks, primaryLinks, secondaryLinks };
}
