import { Link, Typography } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Alert, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { ChargebeeResponse } from 'shared/helpers/ChargebeeWeb';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useBlockFormChanges } from 'shared/hooks/useBlockFormChanges';
import { EncryptIcon } from 'shared/icons/EncryptIcon';
import { EncryptOutlinedIcon } from 'shared/icons/EncryptOutlinedIcon';
import { SeatBasedPricingBox } from 'shared/modules/subscription/core/SeatBasedPricingBox';
import { billingAddressFormErrorsGetter } from 'shared/modules/subscription/core/SubscriptionUtils';
import { trackSubscriptionEvent } from 'shared/modules/subscription/data/SubscriptionAnalytics';
import {
  useBillingAddress,
  useSubscription,
  useSubscriptionAPI,
} from 'shared/modules/subscription/SubscriptionAPI';
import { subscriptionSchema } from 'shared/modules/subscription/SubscriptionDTO';
import { LocationState } from 'shared/modules/subscription/SubscriptionSalesTaxDrawer';
import { useFlag } from 'shared/settings/FeatureToggles';
import { ChargebeeEmptyPlaceholder } from 'shared/ui/ChargebeeEmptyPlaceholder';
import { useShowTrialExpiredDialog } from '../data/useShowTrialExpiredDialog';
import { useCreditCard } from '../PaymentService';
import { PaywallSubscriptionForm } from './PaywallSubscriptionForm';
import { PaywallSubscriptionPlan } from './PaywallSubscriptionPlan';

interface PaywallPaymentCardProps {
  onSubmitSuccess: (cardDetails: LocationState) => void;
}

export function PaywallPaymentCard({
  onSubmitSuccess,
}: PaywallPaymentCardProps) {
  const [pagePath] = useSearchParam('path');
  const [shouldEnableReinitialize, setShouldEnableReinitialize] =
    useState(true);
  const { data: subscriptionBillingAddress } = useBillingAddress();
  const { subscription: subscriptionDetails, billableSeats } =
    useSubscription();
  const { cardRef, isChargebeeAvailable, addCreditCard } = useCreditCard(
    subscriptionDetails?.client_token || '',
  );
  const shouldShowSeatBasedPricing = useFlag('seats_based_pricing');
  const { closeTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { saveBillingAddress } = useSubscriptionAPI();

  const formik = useFormikEnhanced({
    enableReinitialize: shouldEnableReinitialize,
    validateOnBlur: false,
    validationSchema: subscriptionSchema,
    initialValues: {
      zip: subscriptionBillingAddress?.billing_zip || '',
      country: subscriptionBillingAddress?.billing_country || 'US',
      state: subscriptionBillingAddress?.billing_state || '',
      city: subscriptionBillingAddress?.billing_city || '',
      address: subscriptionBillingAddress?.billing_address || '',
      can_use_company_billing_address:
        !!subscriptionBillingAddress?.billing_country ||
        !!subscriptionBillingAddress?.billing_city,
      seats_count: billableSeats?.billable_seats || 1,
    },
    getFormErrors: (error) => {
      return billingAddressFormErrorsGetter(error);
    },
    onSubmit: async (values) => {
      setShouldEnableReinitialize(false);
      const canUseCompanyAddress =
        values.can_use_company_billing_address && !!subscriptionBillingAddress;

      try {
        await saveBillingAddress(
          canUseCompanyAddress
            ? subscriptionBillingAddress
            : {
                billing_country: values.country,
                billing_zip: values.zip,
                billing_state: values.state,
                billing_address: values.address,
                billing_city: values.city,
              },
        );
      } catch (error: unknown) {
        return Promise.reject(error);
      }

      return addCreditCard({});
    },
    onSubmitSuccess: (values: ChargebeeResponse) => {
      const {
        token,
        vaultToken,
        additional_information: { braintree },
      } = values;

      const cardDetails: LocationState = {
        token,
        vaultToken,
        cardDetails: braintree,
        billingAddress: {
          zip: formik.values.zip,
          country: formik.values.country,
          state: formik.values.state,
        },
        seatsCount: formik.values.seats_count,
      };

      if (formik.dirty) {
        trackSubscriptionEvent({
          name: 'Carrier Edited Subscription Billing Address',
        });
      }

      onSubmitSuccess(cardDetails);
    },
  });

  const isDisabledMinus =
    formik.values.seats_count === 1 ||
    formik.values.seats_count === billableSeats?.billable_seats;

  useBlockFormChanges(formik);

  return (
    <Stack space="medium">
      <Typography variant="h3">Add Payment Information</Typography>
      <Stack space="xsmall">
        <Columns space="xxsmall" align="top">
          <Column width="content">
            <EncryptIcon />
          </Column>
          <Column width="fluid">
            <TextBox color="secondary" variant="body">
              We use industry-standard encryption and security protocols to
              safeguard your information.
            </TextBox>
          </Column>
        </Columns>
        <Columns space="xxsmall" align="top">
          <Column width="content">
            <Check fontSize="small" htmlColor={ColorDynamic.Dark100} />
          </Column>
          <Column width="fluid">
            <TextBox color="secondary" variant="body">
              We accept all major credit cards
            </TextBox>
          </Column>
        </Columns>
      </Stack>
      <FormikProvider value={formik}>
        <Form>
          <Stack space="large">
            {isChargebeeAvailable ? (
              <PaywallSubscriptionForm ref={cardRef} />
            ) : (
              <ChargebeeEmptyPlaceholder />
            )}

            <Stack space="small">
              {shouldShowSeatBasedPricing ? (
                <SeatBasedPricingBox isMinusDisabled={isDisabledMinus} />
              ) : (
                <PaywallSubscriptionPlan />
              )}

              <Button
                pending={formik.isSubmitting}
                fullWidth={true}
                startIcon={<EncryptOutlinedIcon />}
                type="submit"
                size="large"
              >
                Continue to Upgrade
              </Button>
              <Button
                disabled={formik.isSubmitting}
                fullWidth={true}
                variant="neutral"
                size="large"
                onClick={() => {
                  trackEvent('Carrier Closed Paywall Dialog', {
                    utm_medium: 'Paywall Dialog',
                    utm_source: pagePath,
                  });
                  closeTrialExpiredDialog();
                  openExternalURL('/loadboard');
                }}
              >
                Access Free Loadboard
              </Button>

              <Inline horizontalAlign="center">
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="caption"
                >
                  By continuing, you agree to our{' '}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.superdispatch.com/terms-of-service"
                  >
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.superdispatch.com/hc/articles/35648690974739"
                  >
                    Refund Policy
                  </Link>
                </Typography>
              </Inline>

              {formik.status.type === 'rejected' && (
                <Alert
                  icon={
                    <Info fontSize="small" htmlColor={ColorDynamic.Red500} />
                  }
                  severity="critical"
                >
                  {formik.status.payload.message}
                </Alert>
              )}
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
}
