import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { updateAuthToken } from 'shared/auth/AuthToken';
import { trackEvent } from 'shared/helpers/Analytics';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { LimitedExperienceDialog } from 'shared/modules/shipper/LimitedExperienceDialog';
import { useShipperListHasLimitedExperience } from 'shared/modules/shipper/ShipperUtils';
import { OffersPageLayout } from '../../core/OffersPageLayout';
import { trackPublicOfferEvent } from '../data/PublicOfferAnalytics';
import {
  AcceptPublicOfferResponseDTO,
  PublicOfferDTO,
} from '../data/PublicOfferDTO';
import { usePublicOfferAPI } from '../data/PublicOffersAPI';
import { PublicOfferDetails } from '../PublicOfferDetails';
import { PublicOfferPageHeader } from '../PublicOfferPageHeader';
import { PublicOfferStepper } from '../PublicOfferStepper';
import { PublicOfferDeclineDialog } from './PublicOfferDeclineDialog';

function PlaceBidButton({
  offer,
  disabled,
}: {
  offer: PublicOfferDTO;
  disabled: boolean;
}) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <Button
      variant="neutral"
      size={isMobile ? 'medium' : 'large'}
      fullWidth={true}
      disabled={disabled}
      onClick={() => {
        trackEvent('Carrier Clicked Place Bid on Load Offer');
        openExternalURL(
          '/tms/login?next=loadboard/loads{?current_load_guid,utm_medium,utm_source}',
          {
            current_load_guid: offer.order.shipper_order_guid,
            utm_source: 'Web CTMS',
            utm_medium: 'Load Offer',
            target: '_blank',
          },
        );
      }}
    >
      Login & Counter Bid
    </Button>
  );
}

interface Props {
  offer: PublicOfferDTO;
}

export function PublicOfferPendingPage({ offer }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const [dialog, setDialog] = useState<'decline' | 'limited-experience'>();
  const { acceptPublicOffer } = usePublicOfferAPI();
  const isMobile = useResponsiveValue(true, false);
  const shouldShowBidButton = offer.is_counter_offer;

  const { hasLimitedExperienceShipper, experienceList } =
    useShipperListHasLimitedExperience([offer.order.broker_guid]);

  const { handleSubmit: acceptOffer, isSubmitting: isAcceptingOffer } =
    useFormikEnhanced({
      initialValues: {},
      onSubmit: () => acceptPublicOffer(offer.guid, !!offer.is_first_offer),
      onSubmitSuccess: (response: AcceptPublicOfferResponseDTO) => {
        if (response.token) {
          updateAuthToken(response.token);
        }
        trackPublicOfferEvent({
          name: 'Accepted Load Offer',
          offer,
          response,
        });
      },
      onSubmitFailure: ({ message }) => {
        addSnackbar(message, { variant: 'error' });
      },
    });

  return (
    <>
      <PublicOfferDeclineDialog
        offer={offer}
        open={dialog === 'decline'}
        onSuccess={() => {
          setDialog(undefined);
        }}
        onClose={() => {
          setDialog(undefined);
        }}
      />

      {experienceList && (
        <LimitedExperienceDialog
          shippersExperience={experienceList}
          action="accept_offer"
          isOpen={dialog === 'limited-experience'}
          onCancel={() => {
            setDialog(undefined);
          }}
          onConfirm={() => {
            setDialog(undefined);
            acceptOffer();
          }}
        />
      )}

      <PublicOfferPageHeader offer={offer} />

      <OffersPageLayout
        header={<PublicOfferStepper />}
        footer={
          <Box width="100%" padding="medium">
            {isMobile ? (
              <Stack space="small">
                {shouldShowBidButton && (
                  <PlaceBidButton offer={offer} disabled={isAcceptingOffer} />
                )}

                <Columns space="small">
                  <Column width="1/2">
                    <Button
                      size="medium"
                      variant="neutral"
                      disabled={isAcceptingOffer}
                      onClick={() => {
                        setDialog('decline');
                      }}
                    >
                      Decline
                    </Button>
                  </Column>
                  <Column width="1/2">
                    <Button
                      size="medium"
                      variant="primary"
                      fullWidth={true}
                      pending={isAcceptingOffer}
                      onClick={() => {
                        acceptOffer();
                      }}
                    >
                      Accept
                    </Button>
                  </Column>
                </Columns>
              </Stack>
            ) : (
              <Columns space="small">
                <Column width={shouldShowBidButton ? 'fluid' : '1/2'}>
                  <Box
                    display="flex"
                    justifyContent={
                      shouldShowBidButton ? 'flex-start' : 'flex-end'
                    }
                  >
                    <Button
                      size="large"
                      variant="neutral"
                      disabled={isAcceptingOffer}
                      onClick={() => {
                        setDialog('decline');
                      }}
                    >
                      Decline
                    </Button>
                  </Box>
                </Column>

                {shouldShowBidButton && (
                  <Column width="content">
                    <PlaceBidButton offer={offer} disabled={isAcceptingOffer} />
                  </Column>
                )}

                <Column width="content">
                  <Button
                    size="large"
                    variant="primary"
                    pending={isAcceptingOffer}
                    onClick={() => {
                      if (hasLimitedExperienceShipper) {
                        setDialog('limited-experience');
                        return;
                      }

                      acceptOffer();
                    }}
                  >
                    Accept
                  </Button>
                </Column>
              </Columns>
            )}
          </Box>
        }
      >
        <PublicOfferDetails offer={offer} />
      </OffersPageLayout>
    </>
  );
}
