import { useState } from 'react';
import { DriverDTO } from 'shared/modules/driver/DriverDTO';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { DriverInviteDrawerLoading } from '../../drivers/core/DriverInviteLoading';
import { LoadDTO } from '../data/LoadDTO';
import {
  LoadAssignDrawer,
  LoadAssignDrawerStep,
  LoadAssignDrawerValues,
} from './LoadAssignDrawer';
import { PendingActivationDriverDialog } from './PendingActivationDriverDialog';

export interface LoadAssignProps {
  load?: LoadDTO;
  onClose: () => void;
  onSubmitSuccess: (values: LoadAssignDrawerValues) => void;
}

export function LoadAssign({
  load,
  onClose,
  onSubmitSuccess,
}: LoadAssignProps) {
  const [createdDriver, setCurrentDriver] = useState<DriverDTO | null>(null);
  const [drawer, setDrawer] = useState<'assign_drawer' | 'paywall_drawer'>(
    'assign_drawer',
  );
  const [step, setStep] = useState<LoadAssignDrawerStep>('assign_driver');
  const [pendingActivationDriver, setPendingActivationDriver] = useState<
    DriverDTO | null | undefined
  >(null);

  const nextPlan = useNextPlanEstimate();

  if (nextPlan.isFetching) {
    return <DriverInviteDrawerLoading open={true} onClose={onClose} />;
  }

  return (
    <>
      <PendingActivationDriverDialog
        onClose={() => {
          setPendingActivationDriver(null);
        }}
        driver={pendingActivationDriver}
      />

      <PlanUpdateDrawer
        open={!!load && drawer === 'paywall_drawer'}
        onClose={() => {
          setDrawer('assign_drawer');
        }}
        onSubmitSuccess={() => {
          setDrawer('assign_drawer');
          setStep('invite_driver');
        }}
      />

      <LoadAssignDrawer
        step={step}
        load={load}
        onSetStep={setStep}
        onSubmitSuccess={onSubmitSuccess}
        onInviteDriver={() => {
          void nextPlan.refetch().then(({ data }) => {
            if (data?.plan) {
              setDrawer('paywall_drawer');
            } else {
              setStep('invite_driver');
            }
          });
        }}
        onSetCurrentDriver={setCurrentDriver}
        onSetPendingActivationDriver={(driver) => {
          setPendingActivationDriver(driver);
        }}
        open={!!load && drawer === 'assign_drawer'}
        onClose={() => {
          onClose();
        }}
      />

      <PendingActivationDriverDialog
        driver={createdDriver}
        onClose={() => {
          setCurrentDriver(null);
        }}
      />
    </>
  );
}
