import { Column, Columns, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { LimitedExperienceDialog } from 'shared/modules/shipper/LimitedExperienceDialog';
import { useShipperListHasLimitedExperience } from 'shared/modules/shipper/ShipperUtils';
import { LinkButton } from 'shared/routing/Links';
import { emitLoadEvent } from '../../loads/data/LoadEvents';
import { trackOffersEvent } from './data/OffersAnalytics';
import { useAcceptOffer } from './data/OffersAPI';
import { OfferDetailsDTO } from './data/OffersDTO';
import { OffersDeclineDialog } from './OffersDeclineDialog';

export interface AcceptedOfferPageFooterProps {
  loadGuid: string;
}

export function AcceptedOfferPageFooter({
  loadGuid,
}: AcceptedOfferPageFooterProps) {
  return (
    <Box
      marginLeft="auto"
      marginRight="auto"
      paddingTop="medium"
      paddingBottom="medium"
    >
      <LinkButton
        size="large"
        variant="neutral"
        to={`/loads/${loadGuid}?utm_medium=load_offer&utm_source=Web+CTMS`}
        data-intercom-target="offer-open-load-button"
      >
        Open Load
      </LinkButton>
    </Box>
  );
}

function PlaceBidButton({
  offer,
  disabled,
}: {
  offer: OfferDetailsDTO;
  disabled: boolean;
}) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <Button
      variant="neutral"
      size={isMobile ? 'medium' : 'large'}
      fullWidth={true}
      disabled={disabled}
      onClick={() => {
        trackOffersEvent({
          name: 'Carrier Clicked Place Bid on Load Offer',
        });
        openExternalURL(
          '/loadboard/loads{?current_load_guid,utm_medium,utm_source}',
          {
            current_load_guid: offer.load.shipper_order_guid,
            utm_source: 'Web CTMS',
            utm_medium: 'Load Offer',
            target: '_blank',
          },
        );
      }}
    >
      Counter Bid
    </Button>
  );
}

export interface PendingOfferPageFooterProps {
  offer: OfferDetailsDTO;
}

export function PendingOfferPageFooter({ offer }: PendingOfferPageFooterProps) {
  const [dialog, setDialog] = useState<'decline' | 'limited-experience'>();
  const isMobile = useResponsiveValue(true, false);
  const shouldShowBidButton = offer.is_counter_offer;

  const { hasLimitedExperienceShipper, experienceList } =
    useShipperListHasLimitedExperience([offer.load.shipper_guid]);

  const acceptOffer = useAcceptOffer({
    onSuccess: () => {
      trackOffersEvent({ name: 'Accepted Load Offer', offer });
      emitLoadEvent('accepted');
    },
  });

  useErrorSnackbar(acceptOffer.error, { onClose: acceptOffer.reset });

  function handleAcceptOffer() {
    if (hasLimitedExperienceShipper) {
      setDialog('limited-experience');
      return;
    }

    acceptOffer.mutate(offer.guid);
  }

  return (
    <>
      <OffersDeclineDialog
        offer={offer}
        open={dialog === 'decline'}
        onSuccess={() => {
          setDialog(undefined);
        }}
        onClose={() => {
          setDialog(undefined);
        }}
      />

      {experienceList && (
        <LimitedExperienceDialog
          shippersExperience={experienceList}
          action="accept_offer"
          isOpen={dialog === 'limited-experience'}
          onCancel={() => {
            setDialog(undefined);
          }}
          onConfirm={() => {
            setDialog(undefined);
            acceptOffer.mutate(offer.guid);
          }}
        />
      )}

      <Box
        width="100%"
        marginLeft="large"
        marginRight="large"
        paddingTop="medium"
        paddingBottom="medium"
      >
        {isMobile ? (
          <Stack space="small">
            {shouldShowBidButton && (
              <PlaceBidButton offer={offer} disabled={acceptOffer.isLoading} />
            )}

            <Columns space="small">
              <Column width="1/2">
                <Button
                  size="medium"
                  variant="neutral"
                  fullWidth={true}
                  disabled={acceptOffer.isLoading}
                  onClick={() => {
                    setDialog('decline');
                  }}
                >
                  Decline
                </Button>
              </Column>
              <Column width="1/2">
                <Button
                  size="medium"
                  variant="primary"
                  fullWidth={true}
                  pending={acceptOffer.isLoading}
                  onClick={handleAcceptOffer}
                >
                  Accept
                </Button>
              </Column>
            </Columns>
          </Stack>
        ) : (
          <Columns space="small">
            <Column width={shouldShowBidButton ? 'fluid' : '1/2'}>
              <Box
                display="flex"
                justifyContent={shouldShowBidButton ? 'flex-start' : 'flex-end'}
              >
                <Button
                  size="large"
                  variant="neutral"
                  disabled={acceptOffer.isLoading}
                  onClick={() => {
                    setDialog('decline');
                  }}
                >
                  Decline
                </Button>
              </Box>
            </Column>

            {shouldShowBidButton && (
              <Column width="content">
                <PlaceBidButton
                  offer={offer}
                  disabled={acceptOffer.isLoading}
                />
              </Column>
            )}

            <Column width="content">
              <Button
                size="large"
                variant="primary"
                pending={acceptOffer.isLoading}
                onClick={handleAcceptOffer}
              >
                Accept
              </Button>
            </Column>
          </Columns>
        )}
      </Box>
    </>
  );
}
