import { Help } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { trackRatingAnalytics } from 'shared/modules/ratings/data/RatingAnalytics';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { ShipperProfileLink } from '../shipper/ShipperProfileLink';
import { CarrierAvatar } from './CarrierAvatar';
import {
  CarrierRatingDTO,
  getCarrierRatingTagDisplayName,
} from './data/CarrierRatingsDTO';
import { Review } from './Review';
import { ReviewResponse } from './ReviewResponse';

function CarrierReviewResponse({
  rating,
  onClickAddResponse,
  onClickDeleteResponse: onClickDelete,
}: CarrierRatingProps) {
  const { data: carrierSettings } = useCarrierSettings();

  if (rating.response) {
    return (
      <ReviewResponse
        author="Response from you"
        avatar={<CarrierAvatar />}
        responseDate={rating.response_date}
        response={rating.response}
        onClickEdit={() => onClickAddResponse?.(rating)}
        onClickDelete={() => onClickDelete?.(rating)}
      />
    );
  }

  if (carrierSettings?.carrier.verification_status === 'approved') {
    return (
      <Button
        onClick={() => {
          onClickAddResponse?.(rating);
        }}
      >
        Add Response
      </Button>
    );
  }

  return null;
}

function CarrierDisputesArticleLink() {
  return (
    <Inline space="xxsmall" verticalAlign="center">
      <Help fontSize="small" color="action" />
      <WebViewLink
        href="https://support.superdispatch.com/hc/articles/35663223245075-How-can-I-dispute-a-negative-rating"
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          trackRatingAnalytics('Carrier Clicked How To Dispute Link');
        }}
      >
        How to dispute a rating
      </WebViewLink>
    </Inline>
  );
}

interface CarrierRatingProps {
  rating: CarrierRatingDTO;
  onClickAddResponse?: (rating: CarrierRatingDTO) => void;
  onClickDeleteResponse?: (rating: CarrierRatingDTO) => void;
}

export function CarrierRating({
  rating,
  onClickAddResponse,
  onClickDeleteResponse,
}: CarrierRatingProps) {
  const isPositiveRating = rating.rating === 'POSITIVE';
  const tags = useMemo(
    () => rating.tags?.map(getCarrierRatingTagDisplayName),
    [rating.tags],
  );

  return (
    <Review
      author={
        <ShipperProfileLink guid={rating.shipper_guid}>
          {rating.shipper_name}
        </ShipperProfileLink>
      }
      reviewDate={rating.created_at}
      variant={isPositiveRating ? 'positive' : 'negative'}
      comment={rating.comment}
      tags={tags}
      status={rating.status === 'PENDING' ? 'pending' : 'published'}
      dispute={<CarrierDisputesArticleLink />}
      response={
        !isPositiveRating && (
          <CarrierReviewResponse
            rating={rating}
            onClickAddResponse={onClickAddResponse}
            onClickDeleteResponse={onClickDeleteResponse}
          />
        )
      }
    />
  );
}

export function CarrierRatingsList({
  ratings,
  onClickAddResponse,
  onClickDeleteResponse,
}: {
  ratings: CarrierRatingDTO[];
  onClickAddResponse?: (rating: CarrierRatingDTO) => void;
  onClickDeleteResponse?: (rating: CarrierRatingDTO) => void;
}) {
  return (
    <Stack space="large">
      {ratings.map((rating) => (
        <CarrierRating
          key={rating.guid}
          rating={rating}
          onClickAddResponse={onClickAddResponse}
          onClickDeleteResponse={onClickDeleteResponse}
        />
      ))}
    </Stack>
  );
}
