import { NullableDateString } from '@superdispatch/dates';
import { useQueryClient } from '@tanstack/react-query';
import { FixedOffsetZone, Info, Settings } from 'luxon';
import { useMemo } from 'react';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { requestCarrierAPIPublic } from 'shared/api/PublicCarrierAPIClient';
import { LocalStore } from 'shared/helpers/Store';
import { VERIFICATION_STATUS } from 'shared/modules/carrier-profile/data/VerificationStatusDTO';
import { Experiment } from 'shared/modules/experiments/ExperimentsAPI';

export type FeatureToggle =
  // Please sort alphabetically.
  | 'admin_login_warning_banner'
  | 'carrier_restrict_changing_load_id'
  | 'carrier_signup_celebration'
  | 'central_dispatch_experiment'
  | 'change_dispatcher_profile_message'
  | 'changed_by_shipper'
  | 'complete_billing_address_warning'
  | 'ctms_disabled_fields'
  | 'ctms_new_dashboard'
  | 'ctms_onboarding'
  | 'ctms_web_chat'
  | 'ctms_zendesk_launcher'
  | 'delete_saved_report'
  | 'dispatcher_management_alert'
  | 'dispatcher_management_deactivation'
  | 'dispatcher_management_invite_form'
  | 'dispatcher_management_join_request_banner'
  | 'dispatcher_management_page'
  | 'driver_update_profile_warning'
  | 'downgrade_subscription'
  | 'geo_tag'
  | 'edit_load'
  | 'intrastate_state_selector'
  | 'latest_activity_logs'
  | 'mobile_webview_carrier_profile'
  | 'monetization_experiment'
  | 'messaging_project'
  | 'multi_carrier_connection_management'
  | 'not_picked_up_vehicles'
  | 'payments_expedited_pay'
  | 'payments_expedited_pay_reminder'
  | 'seats_based_pricing'
  | 'react_loads_page_filter_persist'
  | 'react_notification_banners'
  | 'restricted_w9_files'
  | 'save_new_password_to_generic_user_in_dispatcher'
  | 'shipper_limited_experience'
  | 'show_billing_dialog'
  | 'show_update_password'
  | 'success_reset_password_page'
  | 'trial_expired_banner'
  | 'trips_improvements'
  | 'trip_improvements_v2'
  | 'video_inspection_carrier_tms';

export type FeatureToggles = {
  [F in FeatureToggle]?: null | boolean;
};

export type SuperPayVerificationStatus =
  | 'restricted'
  | 'not_started'
  | 'bank_account'
  | 'bank_account_verification'
  | 'verified'
  | 'bank_account_failed'
  | 'bank_account_errored';

export interface CarrierSettings {
  timezone: string;
  timezone_offset: number; // timezone offset in minutes
  has_terminal: boolean;
  features: FeatureToggles;
  vehicle_types: Record<string, string>;
  paid_methods: Array<{
    value: number;
    text: string;
  }>;
  is_paying: boolean;
  is_super_carrier: boolean;
  groups: Experiment[] | null;
  can_show_shipper_info_in_bol: boolean;
  can_create_load: boolean;
  can_import_load: boolean;
  can_dispatch_to_carrier: boolean;
  company_type: 'owner_operator' | 'owner_operator_pro' | 'fleet';
  carrier: {
    guid: string;
    name: string;
    largest_trailer_capacity: number | null;
    verification_status: (typeof VERIFICATION_STATUS)[number];
    cargo_insurance_expiration_date: NullableDateString;
    is_insurance_renewal_initiated: boolean;
    operation_type: 'Intrastate' | 'Interstate' | null;
  };
  user: {
    guid: string;
    user_hash: string;
    has_access_to_reports: boolean;
    has_accounting_feature: boolean;
    has_permission_to_rate: boolean;
  };
  rating: {
    percentage: number;
    count: number;
  };
  superpay: SuperPayVerificationSettings;
  drivers_can_edit_carrier_info: boolean;
  is_fmcsa_phone_verified: boolean;
}

export interface SuperPayBankErrorNotifications {
  name: string;
  code: string;
  action: string;
  description: string | null;
}

export interface SuperPayVerificationSettings {
  verification: SuperPayVerificationStatus;
  is_verification_available: boolean;
  alert_superpay_load: boolean;
  is_superpay_requested: boolean;
  is_invite_available: boolean;
  show_invite_banner_until: Date | null;
  show_expedited_pay_nudging: boolean;
  payment_error: SuperPayBankErrorNotifications | null;
}

const carrierSettingsStore = {
  set: (data: CarrierSettings | undefined): void => {
    LocalStore.set('carrier_settings', JSON.stringify(data));
  },
  get: (): CarrierSettings | undefined => {
    const value = LocalStore.get('carrier_settings');
    return value ? (JSON.parse(value) as CarrierSettings) : undefined;
  },
};

interface CarrierSettingsOptions {
  enableCache?: boolean;
}
export function useCarrierSettings({
  enableCache = false,
}: CarrierSettingsOptions = {}): APIQueryResult<CarrierSettings> {
  return useAPIQuery(
    ['settings', 'carrier'],
    () => requestCarrierAPI('GET /internal/web/carriers/settings/'),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data) => {
        if (Info.features().zones) {
          Settings.defaultZoneName = data.timezone;
        } else {
          const zone = FixedOffsetZone.instance(data.timezone_offset);

          Settings.defaultZoneName = zone.name;
        }
      },
      ...(enableCache && {
        initialDataUpdatedAt: () => 0,
        initialData: () => carrierSettingsStore.get(),
        onSettled(data) {
          carrierSettingsStore.set(data);
        },
      }),
    },
  );
}
export function useCarrierSettingsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateCarrierSettings() {
      void queryClient.invalidateQueries(['settings', 'carrier']);
    }

    function updateCarrierSettingsCache(
      fn: (value: CarrierSettings) => CarrierSettings,
    ) {
      queryClient.setQueryData<CarrierSettings | undefined>(
        ['settings', 'carrier'],
        (prev) => {
          if (!prev) return;

          return fn(prev);
        },
      );
    }

    return { invalidateCarrierSettings, updateCarrierSettingsCache };
  }, [queryClient]);
}

export function useCarrierPaidMethodOptions(): Map<number, string> {
  const { data } = useCarrierSettings();
  return useMemo(
    () => new Map(data?.paid_methods.map(({ text, value }) => [value, text])),
    [data],
  );
}

export function useCarrierPaidMethodVisibleOptions(
  paidMethodId?: number,
): Map<number, string> {
  const { data } = useCarrierSettings();
  return useMemo(
    () =>
      new Map(
        data?.paid_methods
          .filter((method) =>
            paidMethodId ? paidMethodId === method.value : !!method,
          )
          .map(({ text, value }) => [value, text]),
      ),
    [data, paidMethodId],
  );
}

export function useGlobalFeatures({
  staleTime = Infinity,
  cacheTime = Infinity,
  ...options
}: APIQueryOptions<FeatureToggles> = {}): APIQueryResult<FeatureToggles> {
  return useAPIQuery<FeatureToggles>(
    ['features'],
    () => requestCarrierAPIPublic('GET /internal/web/features/'),
    { ...options, staleTime, cacheTime },
  );
}

export function useCarrierFeatures(): null | FeatureToggles {
  const settings = useCarrierSettings();
  const features = useGlobalFeatures({ enabled: !!settings.error });

  return settings.data?.features ?? features.data ?? null;
}
