import {
  APIPageQueryOptions,
  APIPageQueryResult,
  useAPIPageQuery,
} from 'shared/api/APIPageQuery';
import { APIQueryResult, useAPIQuery } from 'shared/api/APIQuery';
import { requestJSONLoadboardAPI } from 'shared/api/LoadboardAPIClient';
import {
  ShipperExperienceDTO,
  shipperExperienceSchema,
  ShipperProfileDTO,
  shipperProfileSchema,
} from './ShipperProfileDTO';

export function useShipperProfile(
  guid: string | undefined | null,
): APIQueryResult<ShipperProfileDTO> {
  return useAPIQuery(
    ['shippers', 'profile', { guid }],
    () =>
      requestJSONLoadboardAPI([
        'GET /internal/v3/shippers/{guid}/profile',
        { guid },
      ]),
    {
      enabled: !!guid,
      schema: shipperProfileSchema,
    },
  );
}

export function useShipperExperienceList(
  guids: Array<string | undefined>,
  { enabled, ...options }: APIPageQueryOptions<ShipperExperienceDTO>,
): APIPageQueryResult<ShipperExperienceDTO> {
  const filteredGuids = guids.filter(Boolean);
  return useAPIPageQuery(
    ['shippers', 'experience', filteredGuids],
    () =>
      requestJSONLoadboardAPI('POST /internal/v3/shippers/experience', {
        json: { guids },
      }),
    {
      enabled: filteredGuids.length > 0 && enabled,
      schema: shipperExperienceSchema,
      ...options,
    },
  );
}
