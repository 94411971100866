import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, Button, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { SubscriptionDetailsDTO } from 'shared/modules/subscription/SubscriptionDTO';
import { LinkAnchor } from 'shared/routing/Links';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SubscriptionDowngrade } from './SubscriptionDowngrade';

interface SettingsSubscriptionPlanProps {
  subscriptionDetails: SubscriptionDetailsDTO;
  onReactivationSuccess?: () => void;
}

const IS_DOWNGRADE_REQUESTED_STORAGE_KEY = 'is_downgrade_requested';

export function SettingsSubscriptionPlan({
  subscriptionDetails,
}: SettingsSubscriptionPlanProps) {
  const isDowngradingEnabled = useFlag('downgrade_subscription');
  const [modalState, setModalState] = useState<
    'downgradeSuccess' | 'subscriptionDowngrade'
  >();
  const downgradeRequestedStorageItem = useLocalStore(
    IS_DOWNGRADE_REQUESTED_STORAGE_KEY,
  );
  const location = useLocation();

  const upcomingBillingDate =
    subscriptionDetails.subscription?.current_term_end;

  const closeModal = () => {
    setModalState(undefined);
  };

  const isDowngradeRequested = !!downgradeRequestedStorageItem;

  const isSubscriptionOnLitePlan =
    subscriptionDetails.subscription?.plan?.name === 'lite';
  const canDowngrade =
    isDowngradingEnabled && !isDowngradeRequested && isSubscriptionOnLitePlan;

  const isSubscriptionPage = location.pathname.includes('subscription');

  useEffect(() => {
    if (isSubscriptionOnLitePlan && isDowngradeRequested) {
      LocalStore.delete(IS_DOWNGRADE_REQUESTED_STORAGE_KEY);
    }
  }, [isSubscriptionOnLitePlan, isDowngradeRequested]);

  return (
    <>
      <SubscriptionDowngrade
        onClose={closeModal}
        open={modalState === 'subscriptionDowngrade'}
        onSubmitSuccess={() => {
          closeModal();
          setModalState('downgradeSuccess');
          LocalStore.set(IS_DOWNGRADE_REQUESTED_STORAGE_KEY, 'true');
          trackEvent('CTMS: Requested Downgrade to Free Plan');
        }}
      />

      <Dialog open={modalState === 'downgradeSuccess'} onClose={closeModal}>
        <DialogTitle>Downgrade is Requested</DialogTitle>
        <DialogContent>
          <Stack space="large">
            <TextBox>
              Your request has been received and a member of our Support team
              will reach out to you shortly!
            </TextBox>

            <TextBox>
              If you have any questions please contact us at{' '}
              <Link href="mailto:support@superdispatch.com">
                support@superdispatch.com
              </Link>{' '}
              or click the chat icon in the bottom right corner.
            </TextBox>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Got It</Button>
        </DialogActions>
      </Dialog>

      <Stack space="small">
        <Stack space="xxsmall">
          <Inline verticalAlign="bottom">
            <Typography display="inline" variant="h3">
              Plan
            </Typography>

            <Box height="24px">
              <Link
                target="_blank"
                rel="noreferrer noopener"
                href="https://support.superdispatch.com/hc/articles/35612448514195-How-To-Manage-Your-Subscription-Plan-In-Carrier-TMS"
                color="primary"
              >
                Learn More
              </Link>
            </Box>
          </Inline>

          {!isSubscriptionOnLitePlan ? (
            <Typography>
              Your subscription renew on{' '}
              {formatDate(upcomingBillingDate, {
                variant: 'ShortDate',
              })}
            </Typography>
          ) : (
            !!upcomingBillingDate && (
              <Typography>
                Your subscription will end on{' '}
                {formatDate(upcomingBillingDate, {
                  variant: 'ShortDate',
                })}
              </Typography>
            )
          )}

          {isDowngradeRequested && isSubscriptionOnLitePlan && (
            <Tag variant="subtle" color="yellow">
              Downgrade is Requested
            </Tag>
          )}
        </Stack>

        <Stack space={['small', 'xsmall']}>
          <DescriptionItem label="Type:">
            Pro (
            {formatCurrency(subscriptionDetails.subscription?.plan?.amount)}
            /mo)
          </DescriptionItem>

          <DescriptionItem label="Drivers:">
            <Inline space="xsmall" verticalAlign="center">
              {subscriptionDetails.subscription?.plan?.quantity}

              {isSubscriptionPage && isSubscriptionOnLitePlan && (
                <LinkAnchor color="textSecondary" to="/drivers">
                  Manage Drivers
                </LinkAnchor>
              )}
            </Inline>
          </DescriptionItem>
          {subscriptionDetails.subscription?.plan?.amount != null && (
            <DescriptionItem label="Discount for this month:">
              {formatCurrency(subscriptionDetails.subscription?.plan?.amount)}
            </DescriptionItem>
          )}
          <DescriptionItem label="Total price:">
            {formatCurrency(subscriptionDetails.subscription?.plan?.amount)}
            /mo{' '}
            <TextBox color="secondary" variant="body">
              + tax if applicable
            </TextBox>
          </DescriptionItem>
        </Stack>

        {!isSubscriptionPage && isSubscriptionOnLitePlan && (
          <Button
            variant="neutral"
            onClick={() => {
              openExternalURL('/tms/drivers');
            }}
          >
            Manage Drivers
          </Button>
        )}

        {canDowngrade && (
          <Button
            onClick={() => {
              setModalState('subscriptionDowngrade');
              trackEvent('CTMS: Clicked Downgrade to Free Plan');
            }}
            variant="neutral"
          >
            Downgrade to Free Plan
          </Button>
        )}
      </Stack>
    </>
  );
}
