import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import {
  EXPERIMENT_TYPES,
  fetchExperiments,
} from 'shared/modules/experiments/ExperimentsAPI';
import { useSubscriptionDetailsMutation } from 'shared/modules/subscription/SubscriptionAPI';
import { getBackURL } from 'shared/ui/BackButton';

export function useSubscriptionFetchExpirement() {
  const subscriptionDetails = useSubscriptionDetailsMutation();
  const { setOpenSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();

  const handleFetchExperiment = () => {
    void subscriptionDetails.mutateAsync(undefined).then((subscriptionData) => {
      const isSubscriptionOnLitePlan =
        subscriptionData.data.subscription?.plan?.name === 'lite';
      const hasCustomerCardDetails =
        subscriptionData.data.customer?.payment_method;

      if (isSubscriptionOnLitePlan && hasCustomerCardDetails) {
        setOpenSubscriptionCancelledDialog('cancelled');
        openExternalURL(getBackURL(window.location.search, '/loadboard'));
      } else if (isSubscriptionOnLitePlan && !hasCustomerCardDetails) {
        setOpenSubscriptionCancelledDialog('expired');
        openExternalURL(getBackURL(window.location.search, '/loadboard'));
      } else {
        void fetchExperiments().then((data) => {
          const redirectToSLBExperiment = data.data.groups.find(
            (e) => e.number === EXPERIMENT_TYPES['Redirect To SLB'],
          );

          if (redirectToSLBExperiment?.seen) {
            openExternalURL(getBackURL(window.location.search, '/loadboard'));
          } else {
            openExternalURL(getBackURL(window.location.search, '/tms/loads'));
          }
        });
      }
    });
  };
  return { handleFetchExperiment, isLoading: subscriptionDetails.isLoading };
}
