import { useSnackbarStack } from '@superdispatch/ui';
import { ButtonProps } from '@superdispatch/ui-lab';
import { ReactNode, useEffect, useMemo } from 'react';
import { useAPIMutation } from 'shared/api/APIMutation';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { InformationDialog } from 'shared/ui/InformationDialog';
import { isLoadCanceled, LoadDTO } from '../data/LoadDTO';
import { LoadsAPI } from '../data/LoadsAPI';
import { useLoadsContext } from '../data/LoadsContext';

export type LoadActionThreeModesVariant =
  | 'delete'
  | 'archive'
  | 'mark_as_new'
  | 'mark_as_delivered'
  | 'mark_as_in_terminal'
  | 'mark_as_picked_up'
  | 'remove_dispatched_to_carrier_label'
  | 'remove_terminal'
  | 'dispatch_to_carrier'
  | 'restore'
  | 'unassign';

export function getLoadActionThreeModesVariants(): LoadActionThreeModesVariant[] {
  return [
    'delete',
    'archive',
    'mark_as_new',
    'mark_as_delivered',
    'mark_as_in_terminal',
    'mark_as_picked_up',
    'remove_dispatched_to_carrier_label',
    'remove_terminal',
    'dispatch_to_carrier',
    'restore',
    'unassign',
  ];
}

interface CommonConfig {
  title?: ReactNode;
  description?: ReactNode;
  fallbackSuccessMessage: ReactNode;
  confirmButtonText?: ReactNode;
  confirmButtonVariant?: ButtonProps['variant'];
  cancelButtonText?: string;
}

interface Information {
  type: 'information';
}

interface Confirm {
  type?: 'confirm';
  submit: (api: LoadsAPI, load: LoadDTO) => Promise<APIResponse>;
}

type Config = CommonConfig & (Information | Confirm);

function getConfig(
  variant: LoadActionThreeModesVariant,
  load: LoadDTO | undefined,
): Config {
  switch (variant) {
    case 'mark_as_new':
      return {
        title: 'Mark load as New?',
        description:
          'It will not enable drivers to change any existing inspection details if the load was picked up or delivered in mobile app before.',
        fallbackSuccessMessage: `Successfully marked the load ${load?.number} as new.`,
        confirmButtonText: 'Mark as New',
        submit: (api, { guid }) => api.markAsNew(guid),
        type: 'confirm',
      };

    case 'mark_as_picked_up':
      return {
        title: 'Mark Load as Picked up?',
        description:
          'It will disable drivers from being able to complete a pickup inspection and add photos.',
        fallbackSuccessMessage: 'Load marked as picked up',
        confirmButtonText: 'Mark as Picked Up',
        submit: (api, { guid }) => api.markAsPickedUp(guid),
        type: 'confirm',
      };

    case 'mark_as_delivered':
      return {
        title: 'Mark Load as Delivered?',
        description:
          'It will disable drivers from being able to complete a delivery inspections and add photos.',
        fallbackSuccessMessage: 'Load marked as picked up',
        confirmButtonText: 'Mark as Delivered',
        submit: (api, { guid }) => api.markAsDelivered(guid),
        type: 'confirm',
      };

    case 'unassign':
      return {
        title: 'Unassign this load?',
        description: (
          <>
            If the synchronization process is not successfully finished on the
            driver app, you will lose the inspection data of the load.
          </>
        ),
        fallbackSuccessMessage: 'Load unassigned',
        submit: (api, { guid }) => api.unassignDriver(guid),
        type: 'confirm',
      };

    case 'restore':
      return {
        title: 'Restore this load?',
        description: (
          <>
            Restoring this load will bring it back to the active loads tab along
            with the driver’s active load tab. It will be counted in all
            reporting going forward.
          </>
        ),
        fallbackSuccessMessage: 'Load restored',
        submit: (api, { guid }) => api.restoreLoad(guid),
        type: 'confirm',
      };

    case 'remove_terminal':
      return {
        title: 'Remove Terminal?',
        description:
          'Are you sure you want to remove the last Terminal from the order?',
        fallbackSuccessMessage: 'Terminal removed',
        submit: (api, { guid }) => api.removeLastTerminal(guid),
        confirmButtonText: 'Remove',
        type: 'confirm',
      };

    case 'mark_as_in_terminal':
      return {
        title: 'Mark as in Terminal',
        description: 'Are you sure you want to mark this order as in Terminal?',
        fallbackSuccessMessage: 'Marked as in Terminal',
        submit: (api, { guid, terminals }) => {
          const nextTerminal = terminals.find(
            (terminal) => !terminal.is_delivered,
          );
          return api.markAsInTerminal(guid, nextTerminal?.guid || '');
        },
        confirmButtonText: 'Mark as in Terminal',
        type: 'confirm',
      };

    case 'remove_dispatched_to_carrier_label':
      return {
        title: 'Remove "Dispatched to Carrier" label?',
        description: 'It will not remove the load from Shipper TMS',
        fallbackSuccessMessage: 'Removed "Dispatched to Carrier" label',
        submit: (api, { guid }) => api.removeDispatchedToCarrierLabel(guid),
        confirmButtonText: 'Confirm',
        type: 'confirm',
      };

    case 'dispatch_to_carrier':
      return {
        title: 'Dispatch to Carrier',
        description: 'A load with the same name will be created in Shipper TMS',
        fallbackSuccessMessage: 'Load was successfully added to Shipper TMS',
        submit: (api, { guid }) => api.dispatchToCarrier(guid),
        confirmButtonText: 'Confirm',
        type: 'confirm',
      };

    case 'delete': {
      const isLoadCannotBeDeleted =
        !!load && !isLoadCanceled(load) && load.status === 'picked_up';

      if (
        load?.is_created_by_broker &&
        !load.archived &&
        isLoadCannotBeDeleted
      ) {
        const {
          venue: { contacts, name },
        } = load.customer;
        const { email, phone } = contacts[0] || {};
        return {
          title: `Contact ${name}`,
          description: (
            <>
              To delete this load you need to cancel it first. Contact the
              shipper at {email ? email : 'email'} or call{' '}
              {phone ? phone : 'phone'} to cancel.
            </>
          ),
          fallbackSuccessMessage: '',
          type: 'information',
          cancelButtonText: 'Got It',
        };
      }

      return {
        title: `Delete Load${load?.number ? ` ${load.number}` : ''}?`,
        description: (
          <>
            Deleting this load will remove it from all reports, driver history,
            and lose all connection to any shipper. Another option would be to
            archive the load to keep all reporting accurate.
          </>
        ),
        fallbackSuccessMessage: `Load ${load?.number} deleted`,
        submit: (api, { guid }) => api.deleteLoad(guid),
        confirmButtonText: 'Delete',
        confirmButtonVariant: 'critical',
        type: 'confirm',
      };
    }
    case 'archive': {
      const isLoadCannotBeDeleted =
        !!load && !isLoadCanceled(load) && load.status === 'picked_up';

      if (
        load?.is_created_by_broker &&
        !load.archived &&
        isLoadCannotBeDeleted
      ) {
        const {
          venue: { contacts, name },
        } = load.customer;
        const { email, phone } = contacts[0] || {};
        return {
          title: `Contact ${name}`,
          description: (
            <>
              To archive this load you need to cancel it first. Contact the
              shipper at {email ? email : 'email'} or call{' '}
              {phone ? phone : 'phone'} to cancel.
            </>
          ),
          fallbackSuccessMessage: '',
          type: 'information',
          cancelButtonText: 'Got It',
        };
      }

      return {
        fallbackSuccessMessage: 'Load has been archived.',
        submit: (api, { guid }) => api.archiveLoad(guid),
      };
    }
  }
}

interface LoadActionThreeModesValues {
  load: LoadDTO;
}

export interface LoadActionThreeModesProps {
  onClose: () => void;
  load: undefined | LoadDTO;
  variant: LoadActionThreeModesVariant;
  onSubmitSuccess: (
    response: unknown,
    values: LoadActionThreeModesValues,
  ) => void;
}

interface Submit {
  load: LoadDTO;
  onSubmitSuccess: (
    response: unknown,
    values: LoadActionThreeModesValues,
  ) => void;
}

function useSubmit({ load, onSubmitSuccess }: Submit) {
  const { addSnackbar } = useSnackbarStack();
  const { loadsAPI } = useLoadsContext();

  return useAPIMutation(
    (config: CommonConfig & Confirm) => config.submit(loadsAPI, load),
    {
      onSuccess: ({ user_message, data }, config) => {
        addSnackbar(user_message || config.fallbackSuccessMessage, {
          variant: 'success',
        });
        onSubmitSuccess(data, { load });
      },
      onError: ({ message }) => {
        addSnackbar(message, { variant: 'error' });
      },
    },
  );
}

export function LoadActionThreeModes({
  load,
  variant,
  onClose,
  onSubmitSuccess,
}: LoadActionThreeModesProps) {
  const config = useMemo(() => getConfig(variant, load), [variant, load]);
  const { mutate, isLoading } = useSubmit({
    load: load as LoadDTO,
    onSubmitSuccess,
  });

  useEffect(() => {
    if (!config.type && load) {
      mutate(config);
    }
  }, [config, load, mutate]);

  if (config.type === 'information' && load) {
    return (
      <InformationDialog
        title={config.title}
        open={true}
        onClose={onClose}
        cancelButtonProps={{ children: config.cancelButtonText }}
      >
        {config.description}
      </InformationDialog>
    );
  }

  if (config.type === 'confirm' && load) {
    return (
      <ConfirmDialog
        title={config.title}
        open={true}
        onClose={!isLoading ? onClose : undefined}
        cancelButtonProps={{ disabled: isLoading }}
        confirmButtonProps={{
          pending: isLoading,
          variant: config.confirmButtonVariant,
          onClick: () => {
            mutate(config);
          },
          children: config.confirmButtonText,
        }}
      >
        {config.description}
      </ConfirmDialog>
    );
  }
  return null;
}
